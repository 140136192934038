import _ from "lodash"
import React from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, } from "react-google-maps"

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false
};


const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={props.data.zoom}
            defaultCenter={{lat: props.data.lat, lng: props.data.long}}
            defaultOptions={defaultMapOptions}
        >
            <Marker position={{lat: props.data.lat, lng: props.data.long}}/>
        </GoogleMap>
))

export default MyMapComponent
