import React, { Component } from "react"
import ParallaxBackground from "../parallax";

class Quote extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const textColor = this.props.quote.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';
        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.quote.color.slug}`} circle={this.props.circle}>
                <div className={`f-container f-justify-center container small split ${textColor}`}>
                    <div className={"col-6 align-center mt-50 mb-50 pl-10 pr-10"}>
                        <div className={"align-center"}>
                            <span className={"t-40 lh-90"}>&ldquo; </span>
                            <span className={"t-26 lh-90 align-center"}>{this.props.quote.text}</span>
                            <span className={"t-40 lh-90"}> &bdquo;</span>
                        </div>
                        <div className={"f-container f-justify-center mt-40"}>
                            <img width={100} height={100} className={"w-auto h-100 circle"} src={`${process.env.IMAGE_BASE_URL}${this.props.quote.image.url}`} alt={this.props.quote.name}/>
                            <div className={"f-container align-left f-column f-justify-center ml-20"}>
                                <div className={"bold"}>{this.props.quote.name}</div>
                                <div>{this.props.quote.jobtitle} - {this.props.quote.company}</div>
                            </div>
                        </div>
                    </div>
                </div>
                </ParallaxBackground>
            </>
        )
    }
}

export default Quote
