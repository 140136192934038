import React, { Component } from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "underscore";

const Carousel = (props) => {
    const clientList = [];
    let clientTab = [];
    let remainingClients = props.data.clients.length;
    const clientsPerTab = props.data.logosPerTab;

    const clients = _.sortBy( props.data.clients, 'order' );

    clients.forEach(client => {
        clientTab.push(client)
        remainingClients = remainingClients - 1;
        if (clientTab.length === clientsPerTab) {
            clientList.push(clientTab);
            clientTab = [];
        }

        if (remainingClients === 0) {
            if (clientTab.length > 0) {
                clientList.push(clientTab);
            }
        }

    });

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: props.data.speed
    };

    return (
        <>
            <div className={'blue'}>
                <div className={'container small white-text pt-40 pb-40'}>
                    <Slider {...settings}>
                        {clientList.map((tab, index) => <div key={index} className={'f-container f-wrap slider-tab'}>
                            {
                                tab.map((client, index) => <div key={index} style={{width: '20%'}} className={`client-logo h-180 align-center f-container cross-center f-justify-center f-column minw-210`}>
                                    <img width={100} height={60} className={'clientLogo'} style={{width: '80%', height: '70%', objectFit: 'contain'}} alt={client.name} src={`${process.env.IMAGE_BASE_URL}${client.logo.url}`} />
                                </div>)
                            }
                        </div>)}
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default Carousel
