import React from "react"

const Filters = ({filters, callback, setFilters}) => {


    return <div className={'f-container f-wrap'}>
        {
            filters.map((filter, index) => <button key={index} onClick={() => callback(filter)} className={`m-10 white-filter t-12 ${setFilters.indexOf(filter) > -1 ? 'active': ''}`}>
                {filter.tag}
            </button>)
        }
    </div>
}

export default Filters
