import text from "../../data/nl"
import * as React from "react"
import axios from "axios"
import { graphql, useStaticQuery } from "gatsby";
import {useState} from "react";
import people from "../../data/nl.people";
import ParallaxBackground from "../parallax";
import Markdown from "react-markdown";

const DemoRequest = (props) => {
  const [state, setState] = useState({
    name: "",
    emailAddress: "",
    phone: "",
    date: "",
    validations: {
      name: true,
      date: true,
      emailAddress: true,
      phone: true,
    },
    success: null,
    loading: false,
  });

  const buttonColor = props.block.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
  const textColor = props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

  const handleChange = (e) => {

    const name = e.target.name
    const value = e.target.value
    setState(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  const handleSubmit = () => {
    let hasErrors = false

    const validationsCheck = {
      date: true,
      name: true,
      emailAddress: true,
      phone: true,
    }

    if (state.name === "") {
      validationsCheck.name = false
      hasErrors = true
    }
    if (state.emailAddress === "") {
      validationsCheck.emailAddress = false
      hasErrors = true
    }
    if (!/\S+@\S+\.\S+/.test(state.emailAddress)) {
      validationsCheck.emailAddress = false
      hasErrors = true
    }

    if (props.block.phone) {
      if (state.phone === "") {
        validationsCheck.phone = false
        hasErrors = true
      }
    }

    if (props.block.demo_data.length > 0) {
      if (state.date === "") {
        validationsCheck.date = false
        hasErrors = true
      }
    }

    if (hasErrors) {
      setState(prevState => {
        return {
          ...prevState,
          validations: {
            name: validationsCheck.name,
            emailAddress: validationsCheck.emailAddress,
            date: validationsCheck.date,
            phone: validationsCheck.phone,
          },
        }
      })
    } else {

      setState(prevState => {
        return {
          ...prevState,
          loading: true
        }
      });

      const bodyFormData = new FormData()
      const contactPerson = props.block.employee
      bodyFormData.set("name", state.name)
      bodyFormData.set("emailAddress", state.emailAddress)
      bodyFormData.set("date", state.date)
      bodyFormData.set("phone", state.phone)
      bodyFormData.set("event", props.block.event ? props.block.event.Name : '')
      bodyFormData.set("contact", contactPerson.email)
      bodyFormData.set("contactName", `${contactPerson.firstName} ${contactPerson.lastName}`)

      axios({
        method: "post",
        url: "https://ddc.bettywebblocks.com/website/EventRegister",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(() => {
        alert('Uw aanvraag is ontvangen!')
        setState({
          date: "",
          name: "",
          emailAddress: "",
          phone: "",
          validations: {
            date: true,
            name: true,
            emailAddress: true,
            phone: true,
          },
          success: true,
          loading: false,
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }

  const textColumnLeft = <div className={`col-6 p-12 pt-20 ${props.size ? props.size : ''}`}>
    <Markdown className={"lh-150 markdown"} children={props.block.text} escapeHtml={false} /></div>;

  const textColumnRight = <div className={`col-6 p-12 pt-20 ${props.size ? props.size : ''}`}>
    <div className="contact-form hbox" id="ContactForm">
      <div className="full-width">
        {props.block.demo_data.length > 0 && <div>
          <label className="block bold mb-12" htmlFor="date">Datum *</label>
          <div className={state.validations.date ? "valid" : "invalid-radio"}>
            {
              props.block.demo_data.map(node => <label className={'block'}>
                <input
                    name={"date"}
                    value={node.Datum}
                    checked={node.Datum === state.date}
                    onChange={handleChange}
                    type={'radio'}
                /> {node.Datum}
              </label>)
            }
          </div>
        </div>}
        <div className={state.validations.name ? "valid" : "invalid"}>
          <input
              className={`full-width p-8 borderless col-8 ${props.block.color.slug !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
              id={text.contactBlock.form.name}
              placeholder={text.contactBlock.form.name}
              name="name"
              value={state.name}
              onChange={handleChange}
              type="text"
          />
        </div>
        <div className={state.validations.emailAddress ? "valid" : "invalid"}>
          <input
              className={`full-width p-8 borderless col-8 ${props.block.color.slug !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
              id={text.contactBlock.form.emailAddress}
              placeholder={text.contactBlock.form.emailAddress}
              name="emailAddress"
              value={state.emailAddress}
              onChange={handleChange}
              type="text"
          />
        </div>
        {props.block.phone && <div className={state.validations.phone ? "valid" : "invalid"}>
          <input
              className={`full-width p-8 borderless col-8 ${props.block.color.slug !== 'white' ? 'white-transparent white-text white' : 'very-dark-blue-transparent'}`}
              id={text.contactBlock.form.phone}
              placeholder={text.contactBlock.form.phone}
              name="phone"
              value={state.phone}
              onChange={handleChange}
              type="text"
          />
        </div>}
      </div>
      <div className="full-width">

        <button
            className={`${buttonColor} mt-20`}
            onClick={handleSubmit}
        >
          {text.contactBlock.form.submit}
        </button>
      </div>
    </div></div>;


  return (
      <>
        <ParallaxBackground backgroundClass={`full-width ${props.block.color.slug}`} circle={props.circle}>
          <div id={props.block.header.replace(/ /g, '-').toLowerCase()} className={`container split small pt-40 pb-40 ${textColor} ${props.class ? props.class : ''}`}>
            {props.block.header ? <div className={'f-container'}><div className={'col-6'}><h2 className={"bold t-34 lh-105 mb-20 pl-12"} dangerouslySetInnerHTML={{ __html: props.block.header }} /></div>{props.block.headerRight ? <div className={'col-6'}><h2 className={"bold t-34 lh-105 mb-20 pl-12"} dangerouslySetInnerHTML={{ __html: props.block.headerRight }} /></div> : ''}</div> : ''}
            <div className={'f-container'}>
              {textColumnLeft}
              {textColumnRight}
            </div>
          </div>
        </ParallaxBackground>
      </>
  )
}

export default DemoRequest
