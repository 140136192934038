import React, { Component } from "react"
import Person from "./person";
import _ from 'underscore';

class Team extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const employees = _.sortBy( this.props.data.employees, 'order' );

        return (
            <>
                <div className={'blue'}>
                    <div className={'full-width white-text'}>
                        <div className={'container small p-12 pb-40 pt-40'}>
                            <div className={"f-container f-wrap mt-40"}>
                                {
                                    employees.map((person, index) => {
                                        return <div key={index} className={"col-4 minw-250 align-center f-container cross-center f-justify-center"}>
                                            <Person data={person} contact={false} big={true} hover={true} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Team
