import { Link } from "gatsby"
import React, { Component } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw";

class SingleLine extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const buttonColor = this.props.data.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const textColor = this.props.data.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

        const links = this.props.data.links ? <div>
            {this.props.data.links.map((link, index) => <Link key={index} to={link.url}
                                                               className={`mt-30 ${buttonColor}`}>{link.label}</Link>)}
        </div> : ""

        return (
            <>
                <div className={this.props.data.color.slug}>
                <div className={'f-container f-justify-center container small'}>
                    <div className={`align-center m-50 ${textColor}`}>
                        <ReactMarkdown className={`align-center markdown bold t-${this.props.data.size}`} rehypePlugins={[rehypeRaw]} children={this.props.data.text} escapeHtml={false} />
                        {links}
                    </div>
                </div>
                </div>
            </>
        )
    }
}

export default SingleLine
