import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import {Link} from "gatsby";
import Markdown from "react-markdown"
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

class ImageAndTextScaling extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const buttonColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const textColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

        const links = this.props.block.links ? <div>
            {this.props.block.links.map((link, index) => <Link to={link.url}
                                                               className={`mt-30 ${buttonColor}`}>{link.label}</Link>)}
        </div> : ""

        const files = this.props.block.files ? <div>
            {this.props.block.files.map((button, index) => <a key={index} target={'_blank'} href={`${process.env.IMAGE_BASE_URL}${button.file.url}`}
                                                              className={`mt-30 ${buttonColor}`}>{button.label}</a>)}
        </div> : ""

        const imageColumn = <div className={`col-6 block image fit-image f-container f-center-content f-justify-center ${this.props.block.spacing ? 'p-'+this.props.block.spacing : ''} ${this.props.block.height ? 'h-'+this.props.block.height : ''}`}>
            <img alt={this.props.block.header} src={`${process.env.IMAGE_BASE_URL}${this.props.block.image.url}`}/>
        </div>;

        const textColumn = <div className={`col-6 p-12 pb-40 pt-40 ${textColor}`}>
            {this.props.block.header ? <h2 className={"bold t-34 lh-105 mb-20"} dangerouslySetInnerHTML={{ __html: `${this.props.block.header}${this.props.block.header.slice(-1) === '?' ? '' : '.'}` }} /> : ''}
            {/*<div className={"lh-150"} dangerouslySetInnerHTML={{ __html: this.props.block.text }}/>*/}
            <ReactMarkdown className={`lh-150 markdown t-${this.props.block.fontSize}`} rehypePlugins={[rehypeRaw]} children={this.props.block.text} escapeHtml={false} />
            {links}
            {files}
            {this.props.children}
        </div>;

        const content = this.props.block.textSide === "left" ? <div className={'f-container'}>{textColumn}{imageColumn}</div> :
            <div className={'f-container'}>{imageColumn}{textColumn}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.block.color.slug}`} circle={this.props.circle}>
                    <div className={'container small split'}>
                        {content}
                    </div>

                </ParallaxBackground>
            </>
        )
    }
}

export default ImageAndTextScaling
