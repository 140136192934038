import React, { Component, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";


const Person = (props) => {
    let contact;
    let email;
    let big = props.big;
    const [isHover, setIsHover] = useState(false);

    if (props.data.email !== null) {
        email = props.data.email.split('@');
        email = `${email[0]}<wbr>@${email[1]}`;
    } else {
        email = props.data.email;
    }

    if (props.contact)
        contact = <div>
            <div className={'t-12 pt-10'}>{props.data.phone}</div>
            <div className={`t-12 pt-10`}>
                <a aria-label={'Email'} className={`${props.color} block lh-100`} href={`mailto:${props.data.email}`} dangerouslySetInnerHTML={{ __html: email }}/>
            </div>
        </div>

    return(
      <>
          <div className={`f-container f-justify-center full-height ${props.color}`}>
              <div className={'f-container f-column f-justify-center'}>
                  <div className={`${big ? 'w-250' : 'w-150'} align-center f-center m-20`}>
                      <img
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        width={100}
                        height={100}
                        className={`w-auto ${big ? 'h-200 circleBig' : 'h-100 circle'}`}
                        src={`${process.env.IMAGE_BASE_URL}${(isHover && props.data.image2 !== null ? props.data.image2.url : props.data.image.url)}`}
                        alt={props.data.firstName + ' ' + props.data.lastName}
                      />
                      <div className={'bold mb-10 lh-120'}>{props.data.firstName + ' ' + props.data.lastName}</div>
                      {props.function && <div className={'t-12 lh-100'}>{props.data.jobTitle}</div>}
                      {contact}
                      {props.data.linkedin && <div className={'mt-5'}>
                          <a aria-label={'Linkedin'} className={props.color} href={`${props.data.linkedin}`}><FontAwesomeIcon className={'ml-5 mr-5 t-18'} icon={faLinkedin} /></a>
                      </div>}
                      {props.data.link && <div className={'mt-5'}>
                          <a aria-label={'Website'} className={props.color} href={`${props.data.link}`}><FontAwesomeIcon className={'ml-5 mr-5 t-18'} icon={faLink} /></a>
                      </div>}
                  </div>
              </div>
          </div>
      </>
    )
}

export default Person
