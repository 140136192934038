export default {
  key: "AIzaSyAJCOfqwmKPeRg9XGwUXNli7WS_ZTnJkvg",
  lat: 51.577837,
  lng: 4.931233,
  zoom: 9,
  marker: {
    lat: 51.577837,
    lng: 4.931233
  },
}
