import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import {Link} from "gatsby";

class Partners extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const logoStyle = {
            width: "50%",
            height: "130px",
            objectFit: "contain"
        }

        return (
            <>
                <ParallaxBackground backgroundClass={'blue full-width white-text'} circle={this.props.circle}>
                <div className={'full-width white-text'}>
                    <div className={'container small p-12 pb-40 pt-40'}>
                        <h2 className={"bold t-34 lh-105 mb-20"}>{`${this.props.data.header}.`}</h2>
                        <div className={"f-container f-wrap mt-40"}>
                            {
                                this.props.data.partners.map((item, index) => {
                                    return <div key={index} className={"col-4 h-120 align-center f-container cross-center f-justify-center"}>
                                        <Link to={`/${item.page}`}><img width={230} height={120} className={''} style={logoStyle} alt={item.name} src={`${process.env.IMAGE_BASE_URL}${item.logo.url}`} /></Link>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                </ParallaxBackground>
            </>
        )
    }
}

export default Partners
