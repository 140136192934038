import * as React from "react"
import ParallaxBackground from "../parallax";
import Markdown from "react-markdown";
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

const HubSpotForm = (props) => {
    const { loaded, error, formCreated } = useHubspotForm({
        portalId: '19708697',
        formId: props.block.formId,
        target: '#my-hubspot-form'
    });

    const buttonColor = props.block.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
    const textColor = props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

    const textColumnLeft = <div className={`col-6 p-12 pt-20 ${props.size ? props.size : ''}`}>
        <div id="my-hubspot-form"/>
    </div>;

    const textColumnRight = <div className={`col-6 p-12 pt-20 ${props.size ? props.size : ''}`}>
        <Markdown className={"lh-150 markdown"} children={props.block.text} escapeHtml={false} /></div>;


    return (
        <>
            <ParallaxBackground backgroundClass={`full-width ${props.block.color.slug}`} circle={props.circle}>
                <div id={props.block.header.replace(/ /g, '-').toLowerCase()} className={`container split small pt-40 pb-40 ${textColor} ${props.class ? props.class : ''}`}>
                    {props.block.header ? <div className={'f-container'}><div className={'col-6'}><h2 className={"bold t-34 lh-105 mb-20 pl-12"} dangerouslySetInnerHTML={{ __html: props.block.header }} /></div>{props.block.headerRight ? <div className={'col-6'}><h2 className={"bold t-34 lh-105 mb-20 pl-12"} dangerouslySetInnerHTML={{ __html: props.block.headerRight }} /></div> : ''}</div> : ''}
                    <div className={'f-container'}>
                        {textColumnLeft}
                        {textColumnRight}
                    </div>
                </div>
            </ParallaxBackground>
        </>
    )
}

export default HubSpotForm;
