import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import {Link} from "gatsby";
import Markdown from "react-markdown";

class EmbeddedVideoAndText extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const buttonColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const textColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

        const links = this.props.block.links ? <div>
            {this.props.block.links.map((link, index) => <Link key={index} to={link.url}
                                                               className={`mt-30 ${buttonColor}`}>{link.label}</Link>)}
        </div> : ""

        const files = this.props.block.files ? <div>
            {this.props.block.files.map((button, index) => <a key={index} target={'_blank'} href={`${process.env.IMAGE_BASE_URL}${button.file.url}`}
                                                               className={`mt-40 mb-40 ${buttonColor}`}>{button.label}</a>)}
        </div> : ""

        const imageColumn = <div className={"col-6 block image embedded-video"}>
            <iframe width="560" height="315" src={this.props.block.videoUrl}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                         allowFullScreen/>
        </div>;
        const textColumn = <div className={`col-6 p-12 pb-40 pt-40 ${textColor} ${this.props.block.spacing ? `mt-${this.props.block.spacing.top} mb-${this.props.block.spacing.bottom}` : ``}`}>
            {this.props.block.header ? <h2 className={"bold t-34 lh-105 mb-20"}>{this.props.block.header}.</h2> : ''}
            <Markdown
                className={`lh-150 markdown t-${this.props.block.fontSize}`}
                children={this.props.block.text}
                escapeHtml={false} />
            {links}
            {files}
            {this.props.children}
        </div>;

        const content = this.props.block.textSide === "left" ? <div className={'f-container'}>{textColumn}{imageColumn}</div> :
            <div className={'f-container'}>{imageColumn}{textColumn}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.block.color.slug}`} circle={this.props.circle}>
                    <div className={'container small split'}>
                        {content}
                    </div>

                </ParallaxBackground>
            </>
        )
    }
}

export default EmbeddedVideoAndText
