import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import Markdown from "react-markdown"
import {Link} from "gatsby";
import rehypeRaw from "rehype-raw";

class TextAndText extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const buttonColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const textColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

        const links = this.props.block.links ? <div>
            {this.props.block.links.map((link, index) => <Link to={link.url}
                                                               className={`mt-30 ${buttonColor}`}>{link.label}</Link>)}
        </div> : ""

        const textColumnLeft = <div className={`col-6 p-12 pt-20 ${this.props.size ? this.props.size : ''}`}>
            <Markdown
                className={"lh-150 markdown f-container f-column"}
                children={this.props.block.textLeft}
                rehypePlugins={[rehypeRaw]}
                transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
                escapeHtml={false} /></div>;

        const textColumnRight = <div className={`col-6 p-12 pt-20 ${this.props.size ? this.props.size : ''}`}>
            <Markdown
                className={"lh-150 markdown f-container f-column"}
                children={this.props.block.textRight}
                rehypePlugins={[rehypeRaw]}
                transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
                escapeHtml={false} />{links}{this.props.children}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.block.color.slug}`} circle={this.props.circle}>
                    <div id={this.props.block.header ? this.props.block.header.replace(/ /g, '-').toLowerCase() : ''} className={`container split small pt-40 pb-40 ${textColor} ${this.props.class ? this.props.class : ''}`}>
                        {this.props.block.header ? <div className={'f-container'}><div className={'col-6'}><h2 className={"bold t-34 lh-105 mb-20 pl-12"} dangerouslySetInnerHTML={{ __html: `${this.props.block.header}${this.props.block.header.slice(-1) === '?' ? '' : '.'}` }} /></div>{this.props.block.headerRight ? <div className={'col-6'}><h2 className={"bold t-34 lh-105 mb-20 pl-12"} dangerouslySetInnerHTML={{ __html: `${this.props.block.headerRight}.` }} /></div> : ''}</div> : ''}
                        <div className={'f-container'}>
                            {textColumnLeft}
                            {textColumnRight}
                        </div>
                    </div>
                </ParallaxBackground>
            </>
        )
    }
}


export default TextAndText
