import React, { Component } from "react"
import ParallaxBackground from "../parallax";

class ImageAndImage extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const textColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';


        const imageColumn1 = <div className={`col-6 p-12 pb-40 pt-40 block image fit-image f-container f-center-content f-justify-center f-column ${textColor} ${this.props.class ? this.props.class : ''}`}>
            {this.props.block.headerImage1 ? <h2 className={"bold t-24 lh-105 mb-10"}>{this.props.block.headerImage1}.</h2> : ''}
            <img alt={this.props.block.headerImage1} src={`${process.env.IMAGE_BASE_URL}${this.props.block.image1.url}`}/>
        </div>;

        const imageColumn2 = <div className={`col-6 p-12 pb-40 pt-40 block image fit-image f-container f-center-content f-justify-center f-column ${textColor} ${this.props.class ? this.props.class : ''}`}>
            {this.props.block.headerImage2 ? <h2 className={"bold t-24 lh-105 mb-10"}>{this.props.block.headerImage2}.</h2> : ''}
            <img alt={this.props.block.headerImage2} src={`${process.env.IMAGE_BASE_URL}${this.props.block.image2.url}`}/>
        </div>;

        const content = <div className={'f-container'}>{imageColumn1}{imageColumn2}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.block.color.slug}`} circle={this.props.circle}>
                    <div className={'container small split'}>
                        {content}
                    </div>
                </ParallaxBackground>
            </>
        )
    }
}

export default ImageAndImage
