import { Link } from "gatsby";
import React from "react";
import he from 'he';
import moment from 'moment';
import 'moment/locale/nl';
import rehypeRaw from "rehype-raw";
import Markdown from "react-markdown";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const News = ({ item, isBlue, type }) => {
  const breakpoints = useBreakpoint();

  const backgroundImage = {
    backgroundImage: `url(${process.env.IMAGE_BASE_URL}${item.Image.url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
  };

  const date = moment(item.Date, 'YYYY-MM-DD');
  const header = he.decode(item.Title).replace(/(<([^>]+)>)/gi, "");
  const intro = he.decode(item.Content).replace(/(<([^>]+)>)/gi, "");

  return (
    <div className={'col-6 news-article'}>
      <div className={`container f-container full-height small ${isBlue ? 'blue' : 'white'}`}>
        {type === 'even' && !breakpoints.md ? <div className={'col-4'}/> : ''}
        <div className={`${ !breakpoints.md ? 'col-8' : 'col-12'} pb-30`}>
          <Link to={`/nieuws/${item.Slug}`}>
            <img className={`full-width mb-30 mt-30`} src={`${process.env.IMAGE_BASE_URL}${item.Image.url}`}/>
            <div className={`news-info ${isBlue ? 'white-text' : 'deep-blue-text'} ${!breakpoints.md ? (type === 'even' ? 'mr-15' : 'ml-15') : ''} mb-20`}>
              <h2 className={'bold t-34 lh-100 mb-20'} dangerouslySetInnerHTML={{ __html: header }} />
              <div className={'bold mb-15'}>{date.locale('nl').format('LL')}</div>
              <div>
                <Markdown
                  className={'lc-3'}
                  children={intro}
                  rehypePlugins={[rehypeRaw]}
                  skipHtml={true}
                  disallowedElements={['p', 'strong', 'em', 'h3', 'h5']}
                  unwrapDisallowed={true}
                  escapeHtml={false}
                />
              </div>
              <div>
                {<Link to={`/nieuws/${item.Slug}`} className={`mt-30 ${isBlue ? 'white-button' : 'blue-button'}`}>Lees meer</Link>}
              </div>
            </div>
          </Link>
        </div>
        {type === 'odd' && !breakpoints.md ? <div className={'col-4'}/> : ''}
      </div>
    </div>
  );
};

export default News;
