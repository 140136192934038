import { Link } from "gatsby"
import React, { Component } from "react"
import he from 'he';
import moment from 'moment';
import {StaticQuery, graphql} from "gatsby";
import 'moment/locale/nl';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import News from "../blocks/news";

class NewsList extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        return (
            <>
                <div className={'f-container f-wrap pt-75 white'}>
                    <StaticQuery
                        query={graphql`
                        query AllArticles {
                            allStrapiArticles(limit: 10, sort: {fields: Date, order: DESC}) {
                              nodes {
                                  Title
                                  Slug
                                  Content
                                  Date
                                  Image {
                                      url
                                  }
                              }
                          }
                        }
                      `}
                        render={data => {
                            let articles = data.allStrapiArticles.nodes
                            let isBlue = false;
                            let count = 0;
                            return (
                                <>
                                    { articles && articles.map((n, i) => {
                                        if (count === 2) {
                                            count = 0
                                            isBlue = !isBlue;
                                        }
                                        count++;
                                        return <News item={n} type={i % 2 === 0 ? 'even' : 'odd'} isBlue={isBlue}/>
                                    }) }
                                </>
                            )}}
                    />

                </div>
                <div className={'blue-1 white-text'}>
                    <div className={'container small align-center pt-40 pb-40'}>
                        <div className={'t-30 bold'}>
                            Lees meer nieuws in ons archief.
                        </div>
                        <Link to={`/nieuws-archief`}
                              className={`mt-20 f-self-center white-button`}>
                            Archief
                        </Link>
                    </div>
                </div>
            </>
        )
    }

}


export default NewsList
