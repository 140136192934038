import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import Person from "./person";
import _ from "lodash";
import Markdown from "react-markdown"
import {Link} from "gatsby";

class PersonAndText extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const buttonColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-button' : 'blue-button';
        const textColor = this.props.block.color.slug.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';

        const links = this.props.block.links ? <div>
            {this.props.block.links.map((link, index) => <Link to={link.url}
                                                               className={`mt-30 ${buttonColor}`}>{link.label}</Link>)}
        </div> : ""

        const personColumn = <div className={"col-6 split f-container f-justify-center mt-40"}>
            {this.props.block.employees.map(person => <Person function={true} color={textColor} data={person} contact={true} />)}
        </div>;

        const textColumn = <div className={`col-6 p-12 pb-40 pt-40 ${textColor}`}>{this.props.block.header ? <h2 className={"bold t-34 lh-105 mb-20"}>{this.props.block.header}{_.last(this.props.block.header) !== '?' ? '.' : ''}</h2> : ''}
            <Markdown className={"lh-150 markdown"} children={this.props.block.text} escapeHtml={false} />{links}{this.props.children}</div>;


        const content = this.props.block.textSide === "left" ?
            <div id={this.props.block.anchor} className={'f-container split container small'}>{textColumn}{personColumn}</div> :
            <div id={this.props.block.anchor} className={'f-container split container small'}>{personColumn}{textColumn}</div>;

        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.block.color.slug}`} circle={this.props.circle}>
                    {content}
                </ParallaxBackground>
            </>
        )
    }
}

export default PersonAndText
