import React from "react"
import 'moment/locale/nl';
import {Helmet} from "react-helmet";

const HelmetContainer = ({title, description}) =>  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
</Helmet>


export default HelmetContainer
