import React, { Component } from "react"
import Block from "../blocks/block";
import Jumbotron from "./jumbotron";

class MainBlock extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const height = this.props.type === "main" ? "mainCover" : "subCover";

        return (
            <>
                <Block id={this.props.id} image={this.props.cover} size={"big full-height"} classes={`${height} bg-cover`}>
                    <div className="relative f-container f-column f-justify-center container small full-height">
                        {this.props.jumbotron && <Jumbotron light={!!this.props.light} title={this.props.jumbotron.title} subtitle={this.props.jumbotron.subtitle} bpx={this.props.bpx} bpy={this.props.bpy}>
                            {this.props.children}
                        </Jumbotron>}

                    </div>
                </Block>
            </>
        )
    }
}

export default MainBlock
