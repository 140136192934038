import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import 'moment/locale/nl';
import Sitemap from "../components/cms/sitemap";
import conf from "../config/maps.conf"

//blocks
import Helmet from "../components/cms/helmet";
import MainBlock from "../components/cms/main";
import SingleLine from "../components/cms/single-line";
import VideoAndText from "../components/cms/video-text";
import Quote from "../components/cms/quote";
import Clients from "../components/cms/clients-home";
import ImageAndText from "../components/cms/image-text";
import ImageAndTextScaling from "../components/cms/image-text-scaling";
import Partners from "../components/cms/partners-home";
import Form from "../components/cms/form";
import PersonAndText from "../components/cms/person-text";
import Carousel from "../components/cms/carousel";
import ClientCases from "../components/cms/client-cases"
import TextAndText from "../components/cms/text-text";
import Team from "../components/cms/team";
import Map from "../components/cms/map"
import Contact from "../components/cms/contact-request";
import DemoRequest from "../components/cms/DemoRequest";
import EmbeddedVideoAndText from "../components/cms/embedded-video-text";
import EmbeddedVideo from "../components/cms/embedded-video";
import ImageAndImage from "../components/cms/image-image";
import NewsList from "../components/cms/news";
import HubSpotForm from "../components/cms/hubSpot-form";
import Connectors from "../components/cms/connectors";


export default ({data}) => {
    return <Layout>
        <Helmet title={`DDCgroup - ${data.strapiPages.Name}`} description={data.strapiPages.Meta} />
        {data.strapiPages.Content.map(block => {
            switch (block.strapi_component) {
                case "blocks.header":
                    return <MainBlock key={block.id} light={block.light} dark={block.dark} id={"mainCover"} cover={`${process.env.IMAGE_BASE_URL}${block.image.url}`} type={block.Name === "Home" ? "main" : "sub"} jumbotron={block} bpx={65} bpy={50}>
                        {block.CTA && <Link to={block.CTA.url}
                              className={`${block.CTA.color.slug}-button f-align-start mt-30`}>{block.CTA.label}</Link>}
                    </MainBlock>
                case "blocks.image-and-text":
                    if (block.fill) {
                        return <ImageAndText block={block} />
                    } else {
                        return <ImageAndTextScaling block={block} />
                    }
                case "blocks.single-line":
                    return <SingleLine data={block} fontSize={block.size} />
                case "blocks.person-and-text":
                    return <PersonAndText block={block}/>
                case "blocks.video-and-text":
                    return <VideoAndText block={block} textSide={block.textSide} backgroundColor={block.color.slug} />
                case "blocks.text-and-text":
                    return <TextAndText block={block} circle={true} />
                case "blocks.contact-request":
                    return <Contact block={block} />
                case "blocks.video-and-text-you-tube":
                    return <EmbeddedVideoAndText block={block} />
                case "blocks.video-you-tube":
                    return <EmbeddedVideo block={block} />
                case "blocks.demo-request":
                    return <DemoRequest block={block} />
                case "blocks.image-and-image":
                    return <ImageAndImage block={block} />
                case "blocks.client-quote":
                    return <Quote quote={block} circle={false} />
                case "blocks.client-selection":
                    return <Clients data={block} circle={true} />
                case "blocks.partner-selection":
                    return <Partners data={block} circle={true} />
                case "blocks.contact-form":
                    return <Form data={block}/>
                case "blocks.client-carousel":
                    return <Carousel data={block} />
                case "blocks.client-cases":
                    return <ClientCases data={block} />
                case "blocks.team":
                    return <Team data={block} />
                case "blocks.news":
                    return <NewsList data={block} />
                case "blocks.hub-spot-form":
                    return <HubSpotForm block={block} />
                case "blocks.map":
                    return <Map
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${conf.key}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        data={block} />
            }
        })}
        {
            data.strapiPages.Name === "TimeXtender" ? <Connectors header={'Connectors'} backgroundColor={'blue'} /> : ''
        }
        <Sitemap/>
    </Layout>
}

export const query = graphql`
    query PageQuery($slug: String!) {
        strapiPages(URL: { eq: $slug }) {
            strapiId
            Name
            Meta
            Content
        }
 
    }
`;
