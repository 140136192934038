import React, { Component } from "react"
import axios from "axios"
import Person from "./person";
import BarLoader from "react-spinners/BarLoader"

class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            emailAddress: "",
            message: "",
            phone: "",
            company: "",
            validations: {
                name: true,
                emailAddress: true,
                message: true,
                company: true,
                phone: true
            },
            success: null,
            loading: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <div className={'container split small p-12 pb-40 pt-60'}>
                <div className={'col-6 align-center'}>
                    <h2 className={"bold t-30 lh-105 mb-10"} dangerouslySetInnerHTML={{ __html: this.props.data.header }} />
                    <h3 className={"t-30 lh-105 mb-20"}>{this.props.data.subheader}</h3>
                </div>
                <div className={'f-container'}>
                    <div className={'col-6'}>
                        <Person color={'deep-blue-text'} function={true} data={this.props.data.employee} contact={true} />
                    </div>
                    <div className="contact-form hbox col-6" id="ContactForm">
                        <div className="full-width">
                            <div className={"col-6"}>
                            <div className={this.state.validations.name ? "valid" : "invalid"}>
                                <input
                                    className="full-width p-8 borderless"
                                    id={this.props.data.name}
                                    name="name"
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    placeholder={this.props.data.name}
                                    type="text"
                                />
                            </div>
                            <div className={this.state.validations.company ? "valid" : "invalid"}>
                                <input
                                    className="full-width p-8 borderless"
                                    id={this.props.data.company}
                                    name="company"
                                    value={this.state.company}
                                    onChange={this.handleChange}
                                    placeholder={this.props.data.company}
                                    type="text"
                                />
                            </div>
                            <div className={this.state.validations.emailAddress ? "valid" : "invalid"}>
                                <input
                                    className="full-width p-8 borderless"
                                    id={this.props.data.emailAddress}
                                    name="emailAddress"
                                    onChange={this.handleChange}
                                    value={this.state.emailAddress}
                                    placeholder={this.props.data.emailAddress}
                                    type="emailAddress"
                                />
                            </div>
                            <div className={this.state.validations.phone ? "valid" : "invalid"}>

                                <input
                                    className="full-width p-8 borderless"
                                    id={this.props.data.phone}
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    placeholder={this.props.data.phone}
                                    type="text"
                                />
                            </div>
                            </div>
                            <div className={'col-12 full-width'}>
                            <div className={this.state.validations.message ? "valid" : "invalid"}>
                                <textarea
                                    className="full-width p-8 borderless mt-0 mb-0 h-175"
                                    id={this.props.data.message}
                                    name="message"
                                    value={this.state.message}
                                    placeholder={this.props.data.message}
                                    onChange={this.handleChange}
                                />
                            </div>
                            </div>
                        </div>
                            <button
                                className="blue-button"
                                onClick={this.handleSubmit}
                            >
                                {this.props.data.submit}
                            </button>
                        <div className={"t-16 bold ml-25 relative"}>
                            <div className={"center-vertically-and-horizontally " + (this.state.loading ? "opacity-100" : "opacity-0")}>
                                <BarLoader sizeUnit={"px"} size={100} color={'white'} loading={this.state.loading} />
                            </div>
                            <span className={"transition-opacity "+(this.state.success ? "opacity-100" : "opacity-0")}>{this.props.data.thanks}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }

    handleSubmit() {
        let hasErrors = false;
        const validations = {
            name: true,
            company: true,
            emailAddress: true,
            message: true,
            phone: true
        }
        if (this.state.name === "") {
            validations.name = false
            hasErrors = true;
        }
        if (this.state.emailAddress === "") {
            validations.emailAddress = false
            hasErrors = true;
        }
        if (!/\S+@\S+\.\S+/.test(this.state.emailAddress)) {
            validations.emailAddress = false
            hasErrors = true;
        }
        if (this.state.message === "") {
            validations.message = false
            hasErrors = true;
        }

        if (hasErrors) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    validations: {
                        name: validations.name,
                        company: validations.company,
                        emailAddress: validations.emailAddress,
                        message: validations.message,
                        phone: validations.phone
                    },
                }
            })
        } else {

            this.setState(prevState => {
                return {
                    ...prevState,
                    loading: true
                };
            });

            const bodyFormData = new FormData();
            bodyFormData.set('name', this.state.name)
            bodyFormData.set('company', this.state.company)
            bodyFormData.set('emailAddress', this.state.emailAddress)
            bodyFormData.set('message', this.state.message)
            bodyFormData.set('phone', this.state.phone)

            axios({
                method: 'post',
                url: 'https://ddc.bettywebblocks.com/website/addcontactform',
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(() => {
                this.setState(() => {
                    return {
                        name: "",
                        company: "",
                        emailAddress: "",
                        message: "",
                        phone: "",
                        success: true,
                        loading: false
                    }
                })
            }).catch(err => {
                console.log(err)
            });
        }
    }
}

export default Form
