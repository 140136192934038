import React, { Component } from "react"
import ParallaxBackground from "../parallax";
import {graphql, StaticQuery} from "gatsby";
import connectorData from "../../data/connectors.json";

class Connectors extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        const textColor = this.props.backgroundColor.indexOf('blue') > -1 ? 'white-text' : 'deep-blue-text';


        return (
            <>
                <ParallaxBackground backgroundClass={`full-width ${this.props.backgroundColor}`} circle={this.props.circle}>
                    <div id={this.props.header.replace(/ /g, '-').toLowerCase()} className={`container small pt-40 pb-40 ${textColor}`}>
                        {this.props.header ? <h1 className={"bold t-34 lh-105 mb-20 pl-12"}>{this.props.header}.</h1> : ''}
                        <div className={'f-container f-wrap'}>

                            <StaticQuery query={
                                graphql`
                                query AllConnectors {
                                  allStrapiTimeXtenderConnectors {
                                    nodes {
                                      connectors {
                                        data {
                                          Name
                                          Type
                                        }
                                      }
                                    }
                                  }
                                }
                                `
                            }
                             render={data => {
                                 return (
                                     <>
                                         {data.allStrapiTimeXtenderConnectors.nodes[0].connectors.data.map(item => <div
                                             className={'col-4 p-10 lh-100'}>{item.Name}</div>)
                                         }
                                         </>
                                 )
                             }}
                        />

                        </div>
                    </div>
                </ParallaxBackground>
            </>
        )
    }
}

export default Connectors
