import React, {Component, useState} from "react"
import FilterBlock from "./filters"
import ReactMarkdown from "react-markdown"
import {StaticQuery, graphql} from "gatsby";
import rehypeRaw from "rehype-raw";

const ClientCases = (props) => {

    const [filters, setFilters] = useState(props.data.client_tags);
    const [active, setActive] = useState(false);

    const manageFilter = (filter) => {
        let target = filters.find(f => f.tag === filter.tag);

        if (!active) {
            setFilters([filter])
            setActive(true)
        } else {
            if (!target) {
                setFilters([filter])
            } else {
                setFilters(filters.filter(f => f.tag !== filter.tag))
            }
        }

        const element = document.getElementById('cases');

        const yOffset = -50;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }


    if (filters.length === 0) {
        setFilters(props.data.client_tags)
        setActive(false)
    }

    return (
        <>
            <div className={'blue-1 relative overflow-hidden'}>
                <div className={'container small white-text pt-40 pb-40'}>
                    {props.data.header ? <h2 className={"bold t-34 lh-105 mb-20"} dangerouslySetInnerHTML={{ __html: `${props.data.header}.` }} /> : ''}
                    <div>
                        <h3 className={'bold'}>Oplossingen:</h3>
                        <FilterBlock filters={props.data.client_tags.filter(tag => tag.group === "solution")} callback={manageFilter} setFilters={filters} />
                        <h3 className={'bold'}>Producten:</h3>
                        <FilterBlock filters={props.data.client_tags.filter(tag => tag.group === "product")} callback={manageFilter} setFilters={filters} />
                        <h3 className={'bold'}>Industrieën:</h3>
                        <FilterBlock filters={props.data.client_tags.filter(tag => tag.group === "industry")} callback={manageFilter} setFilters={filters} />
                    </div>

                </div>
            </div>
                <StaticQuery
                    query={graphql`
                        query ClientCases {
                            allStrapiClients {
                                edges {
                                    node {
                                        case {
                                            case
                                            client_tags {
                                                tag
                                                group
                                            }
                                            id
                                            result
                                            solution
                                        }
                                        name
                                        logo {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                      `}
                    render={data => {
                        let clients = data.allStrapiClients.edges.filter(c => c.node.case !== null)

                        return (
                            <div id={'cases'}>
                            {
                                clients.map((client, index) => {
                                    client = client.node
                                    let show = false;
                                    let tagsToShow = [];
                                    client.case.client_tags.forEach(tag => {
                                        filters.forEach(filter => {
                                            if (filter.tag === tag.tag)
                                                show = true;
                                        })
                                    })

                                    return show ? <div key={index} className={`${index % 2 === 0 ? 'blue' : 'blue-1'} pt-40 pb-40`}>
                                        <div className={`container small f-container f-column white-text`}>
                                            <div className={'col-3'}>
                                                <img width={170} height={100} style={{width: '170px', height: '100px', objectFit: 'contain'}} alt={client.name} src={`${process.env.IMAGE_BASE_URL}${client.logo.url}`} />
                                            </div>
                                            <h2 className={'t-20 bold mt-10'}>{client.name}</h2>
                                            {
                                                client.case.client_tags.forEach(tag => {
                                                    props.data.client_tags.forEach(filter => {
                                                        if (filter.tag === tag.tag)
                                                            tagsToShow.push(filter.tag)
                                                    })
                                                })
                                            }
                                            <div className={'f-container'}>
                                                {
                                                    tagsToShow.filter((item, pos) => {
                                                        return tagsToShow.indexOf(item) === pos;
                                                    }).map((tag, index) => <div key={index} className={'t-14 pr-15 pb-10 light-grey-text'}>{tag}</div>)
                                                }
                                            </div>
                                            <div className={'f-container case-content'}>
                                                <div className={'col-4 pr-10'}>
                                                    <h3 className={'bold mb-10 t-20'}>Case</h3>
                                                    <ReactMarkdown className={"lh-150 markdown"} rehypePlugins={[rehypeRaw]} children={client.case.case} escapeHtml={false} />
                                                </div>
                                                <div className={'col-4 pl-10 pr-10'}>
                                                    <h3 className={'bold mb-10 t-18'}>Oplossing</h3>
                                                    <ReactMarkdown className={"lh-150 markdown"} rehypePlugins={[rehypeRaw]} children={client.case.solution} escapeHtml={false} />
                                                </div>
                                                <div className={'col-4 pl-10'}>
                                                    <h3 className={'bold mb-10 t-20'}>Resultaat</h3>
                                                    <ReactMarkdown className={"lh-150 markdown"} rehypePlugins={[rehypeRaw]} children={client.case.result} escapeHtml={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''})
                            }
                        </div>
                    )}}
              />
        </>
    )
}


export default ClientCases
