import {graphql, Link, StaticQuery} from "gatsby"
import React, { Component } from "react"
import Logo from "../../images/Logo_ddc_white.png";
import ParallaxBackground from "../parallax";
import menuData from "../../data/nl.menu";
import data from "../../data/nl";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

class Sitemap extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {

        return (
            <>
                <ParallaxBackground backgroundClass={'blue full-width white-text'} circle={true}>
                    <div className={'full-width white-text'}>
                        <div className={'container small p-12 pb-40 pt-60'}>
                            <img width={200} height={93} src={Logo} alt={"DDC Logo"} />
                            <div className={"f-container mt-40 sitemap f-wrap"}>
                                <StaticQuery
                                    query={graphql`
                                        query MenuSitemap {
                                          allStrapiMenu {
                                            nodes {
                                              locale
                                              structure {
                                                menu {
                                                  name
                                                  subitems {
                                                    name
                                                    subitems {
                                                      name
                                                      url
                                                    }
                                                    url
                                                  }
                                                  url
                                                }
                                              }
                                            }
                                          }
                                          strapiCompanyData {
                                            building
                                            email
                                            phone
                                            street
                                            zipcode
                                          }
                                        }
                                      `}
                                    render={gqlData => {
                                        const pages = gqlData.allStrapiMenu.nodes.find(node => node.locale === "nl-NL");
                                        const menu = pages.structure.menu;
                                        const companyData = gqlData.strapiCompanyData;

                                        return (
                                            <>
                                                {
                                                    menu.map((item, index) => {
                                                        if (item.name === "Oplossingen") {
                                                            return <div key={index} className={'f-grow-3'}>
                                                                <div className={'bold t-16 mb-10'}>
                                                                    <Link className={'hover_u'} to={item.url}>{item.name}</Link>
                                                                </div>
                                                                <div className={'f-container f-column f-wrap h-550 sitemap-container'}>
                                                                    {
                                                                        item.subitems.map((item, index) => {
                                                                            return <div key={index} className={'col-6'}>
                                                                                <div className={'t-14 bold lh-110 pb-5'}>
                                                                                    <Link className={'hover_u'} to={item.url} dangerouslySetInnerHTML={{ __html: item.name }}/>
                                                                                </div>
                                                                                <div className={'mb-10'}>
                                                                                    {item.subitems.map((item, index) => {
                                                                                        return <div key={index} className={'t-14 lh-110 pb-10'}>
                                                                                            <Link className={'hover_u'} to={item.url} dangerouslySetInnerHTML={{ __html: item.name }}/>
                                                                                        </div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        } else if (item.name === "Made by DDC") {
                                                            return <div key={index} className={'f-grow-1'}>
                                                                <div className={'bold t-16 mb-10'}>
                                                                    <Link className={'hover_u'} to={item.url}>{item.name}</Link>
                                                                </div>
                                                                <div className={'f-container f-column f-wrap h-550'}>
                                                                    {
                                                                        item.subitems.map((item, index) => {
                                                                            return <div key={index} className={'col-12'}>
                                                                                <div className={'t-14 bold lh-110'}>
                                                                                    <Link className={'hover_u'} to={item.url}>{item.name}</Link>
                                                                                </div>
                                                                                <div className={'mb-10'}>
                                                                                    {item.subitems.map((item, index) => {
                                                                                        return <div key={index} className={'t-14 lh-110'}>
                                                                                            <Link className={'hover_u'} to={item.url}>{item.name}</Link>
                                                                                        </div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        } else {
                                                            return;
                                                        }
                                                    })
                                                }
                                                <div>
                                                    <div className={'bold t-16 mb-10'}>
                                                        <Link className={'hover_u'} to={menu[2].url}>{menu[2].name}</Link>
                                                    </div>
                                                    <div className={'bold t-16 mb-10'}>
                                                        <Link className={'hover_u'} to={menu[3].url}>{menu[3].name}</Link>
                                                    </div>
                                                    <div className={'bold t-16 mb-10'}>
                                                        <Link className={'hover_u'} to={menu[4].url}>{menu[4].name}</Link>
                                                    </div>
                                                    <div className={'bold t-16 mb-10'}>
                                                        <Link className={'hover_u'} to={menu[6].url}>{menu[6].name}</Link>
                                                    </div>
                                                    <div className={'bold t-16 mb-10 pt-20'}>
                                                        <Link className={'hover_u'} to={menu[5].url}>{menu[5].name}</Link>
                                                    </div>

                                                    <div className={'bold t-14'}>
                                                        <div className={'mb-5 hover_u'}><a href={`mailto:${companyData.email}`}>{companyData.email}</a></div>
                                                        <div className={'mb-5 hover_u'}><a href={`tel:${companyData.phone}`}>{companyData.phone}</a></div>
                                                        <div>{companyData.building}</div>
                                                        <div>{companyData.street}</div>
                                                        <div>{companyData.zipcode}</div>
                                                    </div>
                                                    <div className={'bold t-16 mb-10 pt-20'}>
                                                        <Link className={'hover_u'} to={'/privacyverklaring'}>Privacyverklaring</Link>
                                                    </div>
                                                </div>
                                            </>
                                        )}}
                                />
                            </div>
                        </div>
                    </div>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `(function(){ window.ldfdr = window.ldfdr || {}; (function(d, s, ss, fs){ fs = d.getElementsByTagName(s)[0]; function ce(src){ var cs = d.createElement(s); cs.src = src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1); } ce(ss); })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_bElvO73qbJGaZMqj.js'); })();`,
                        }}
                    />
                </ParallaxBackground>
            </>
        )
    }
}

export default Sitemap
